import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  useState,
} from 'react';

interface PostIdContextProviderProps {
  postIdData?: any;
  setPostIdData: Dispatch<any>;
  loading?: boolean;
  setIsLoading: Dispatch<boolean>;
}

export const PostIdContext = createContext<PostIdContextProviderProps>({
  setPostIdData: () => null,
  setIsLoading: () => null,
});

const PostIdContextProvider: FunctionComponent = ({ children }) => {
  const [postIdData, setPostIdData] =
    useState(/*{ApplicationResponse: {kreditKarteBewilligtField: "1", refNoField: "df", langLinkField: "https://"}}*/);
  const [loading, setIsLoading] = useState<boolean>(false);

  return (
    <PostIdContext.Provider
      value={{
        postIdData,
        setPostIdData,
        loading,
        setIsLoading,
      }}
    >
      {children}
    </PostIdContext.Provider>
  );
};

export default PostIdContextProvider;
