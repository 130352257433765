import { createContext, Dispatch, FunctionComponent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface PrefillContextProps {
  defaultValues?: FormValues;
  setDefaultValues: Dispatch<FormValues | undefined>;
  prefillValuesAreLoading: boolean;
  setPrefillValuesAreLoading: Dispatch<boolean>;
  prefillValues?: FormValues;
  setPrefillValues: Dispatch<FormValues>;
}

export interface FormValues {
  [key: string]: any;
}

interface PrefillContextProviderProps {}

export const PrefillContext = createContext<PrefillContextProps>(
  {} as PrefillContextProps
);

const PrefillContextProvider: FunctionComponent<
  PrefillContextProviderProps
> = ({ children }) => {
  const [defaultValues, setDefaultValues] = useState<FormValues | undefined>();
  const [prefillValuesAreLoading, setPrefillValuesAreLoading] =
    useState<boolean>(false);
  const [prefillValues, setPrefillValues] = useState<FormValues | undefined>({
    ForeignKey: uuidv4(),
  });

  return (
    <PrefillContext.Provider
      value={{
        defaultValues,
        setDefaultValues,
        prefillValuesAreLoading,
        setPrefillValuesAreLoading,
        prefillValues,
        setPrefillValues,
      }}
    >
      {children}
    </PrefillContext.Provider>
  );
};

export default PrefillContextProvider;
