import { createContext, Dispatch, FunctionComponent, useState } from 'react';

interface SafeAndRetrieveContextProps {
  saveAndRetrieveConsent: boolean;
  setSaveAndRetrieveConsent: Dispatch<boolean>;
  saveAndRetrieveDeleteId?: string;
  setSaveAndRetrieveDeleteId: Dispatch<string>;
}

export const SafeAndRetrieveContext =
  createContext<SafeAndRetrieveContextProps>({} as SafeAndRetrieveContextProps);

const SaveAndRetrieveProvider: FunctionComponent = ({ children }) => {
  const [saveAndRetrieveConsent, setSaveAndRetrieveConsent] =
    useState<boolean>(false);
  const [saveAndRetrieveDeleteId, setSaveAndRetrieveDeleteId] = useState<
    string | undefined
  >();
  return (
    <SafeAndRetrieveContext.Provider
      value={{
        saveAndRetrieveConsent,
        setSaveAndRetrieveConsent,
        saveAndRetrieveDeleteId,
        setSaveAndRetrieveDeleteId,
      }}
    >
      {children}
    </SafeAndRetrieveContext.Provider>
  );
};

export default SaveAndRetrieveProvider;
