import { createContext } from 'react';

interface MetaDataContextInterface {
  meta?: {
    configVersion?: string;
  };
}

const MetaDataContext = createContext<MetaDataContextInterface>({
  meta: {
    configVersion: '',
  },
});

export default MetaDataContext;
