import { createContext } from 'react';

export const DataSchemaContext = createContext<{
  validationSchema: any;
  errorSchema: any;
}>({
  validationSchema: {},
  errorSchema: {},
});

export default DataSchemaContext;
