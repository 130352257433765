import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { To, useNavigate, useParams } from 'react-router-dom';
import { PageStepperContext } from './formBuilder/pageStepperContext';

export interface IError {
  text?: string;
  header?: string;
  status?: string;
}

interface GlobalErrorProviderProps {
  error?: IError;
  setError: (error: IError) => void;
}

export const GlobalErrorStateContext = createContext<GlobalErrorProviderProps>(
  {} as GlobalErrorProviderProps
);

const GlobalErrorProvider: FunctionComponent = ({ children }) => {
  const [error, setError] = useState<IError>();
  return (
    <GlobalErrorStateContext.Provider value={{ error, setError }}>
      {children}
    </GlobalErrorStateContext.Provider>
  );
};

export const useErrorPage = () => {
  const { setError } = useContext(GlobalErrorStateContext);
  const navigate = useNavigate();
  const { project } = useParams();
  const { setStep } = useContext(PageStepperContext);
  const handleError = (error: IError) => {
    setError(error);
    console.error(
      `Error status: ${error.status}, header: ${error.header}, text: ${error.text}`
    );
    navigate(('/' + project + '/error') as To);
    setStep(null);
  };
  return { handleError };
};

export default GlobalErrorProvider;
