export const PB_MGM_REF_PARAMETER = 'mgm-ref';

export function uriContainsAllNecessaryExternalUrlParameters(
  urlParameter: string[],
  urlSearchParams: URLSearchParams,
  project: string | undefined
) {
  if (
    (urlSearchParams.has('code') && urlSearchParams.has('state')) ||
    urlSearchParams.has('sarcode')
  )
    return true;
  else if (project === 'visa')
    return (
      urlSearchParams.has('sales_code') ||
      urlSearchParams.has(PB_MGM_REF_PARAMETER)
    );
  else
    return urlParameter.every(
      (external_parameter) =>
        urlSearchParams.has(external_parameter) ||
        external_parameter === 'Alias'
    );
}
