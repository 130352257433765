import { Button, Typography } from '@mui/material';
import { FunctionComponent, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ErrorImage } from '../assets/img/error/undraw_cancel_re_ctke.svg';
import { URIParameterContext } from './contexte/URIParameterContext';
import { GlobalErrorStateContext } from './globalErrorContext';
import { analyticsStrategy } from './analytics/AnalyticsInstance';

const GenericErrorPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { URIParameter } = useContext(URIParameterContext);

  const { error } = useContext(GlobalErrorStateContext);

  const { project } = useParams();

  useEffect(() => {
    if (!error) navigate('/' + project?.concat('?').concat(URIParameter));

    analyticsStrategy.pageChange({
      pageName: 'error',
      pageType: 'error',
      errorID: error?.status,
      errorName: error?.text,
    });
  }, []);

  const handleReturn = () => {
    window.sessionStorage.clear();
    window.location.replace('/' + project?.concat('?').concat(URIParameter));
  };

  return (
    <div
      style={{
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <div>
        <ErrorImage />
        <br />
        <Typography variant="h3" style={{ fontWeight: 'bold' }}>
          {error?.header ?? <p>Irgendetwas lief schief..</p>}
        </Typography>
        <Typography variant="subtitle1" style={{ marginBottom: '100px' }}>
          {error?.text ?? (
            <p>
              Leider kam es zu einem Fehler, das tut uns sehr leid.
              <br />
              Bitte kehre zur Antragsstrecke zurück und versuche es erneut.
            </p>
          )}
        </Typography>
        <Button
          onClick={() => handleReturn()}
          size="large"
          color="primary"
          variant="contained"
        >
          Jetzt neu starten
        </Button>
      </div>
    </div>
  );
};

export default GenericErrorPage;
