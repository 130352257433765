import { constants } from '../../constants';
import { MatomoAnalyticsStrategy } from './MatomoAnalyticsStrategy';
import { NoAnalyticsStrategy } from './NoAnalyticsStrategy';
import { PaybackAnalyticsStrategy } from './PaybackAnalyticsStrategy';

export const analyticsStrategy = Object.freeze(() => {
  switch (constants.ANALYTICS_STRATEGY?.toLowerCase()) {
    case 'matomo':
      return new MatomoAnalyticsStrategy();
    case 'payback':
      return new PaybackAnalyticsStrategy();
    default:
      return new NoAnalyticsStrategy();
  }
})();
