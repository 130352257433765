import { IAnalyticsStrategy, PageInfo } from './IAnalyticsStrategy';
import {
  LoginStatus,
  PageType,
  updatePageName,
} from '../../components/analytics/analytics';

declare global {
  interface Window {
    __satelliteLoaded?: undefined | boolean;
    _satellite?: {
      track: (event: 'pageload') => void;
    };
  }
}

const TRACKING_SCRIPT_READY_EVENT_NAME = 'adobeLaunchLoaded';

export class PaybackAnalyticsStrategy implements IAnalyticsStrategy {
  constructor() {
    console.log('Initializing PaybackAnalyticsStrategy');
  }

  listenerAdded: boolean = false;
  currentPageName?: string;

  pageChange = (newPageInfo: PageInfo): void => {
    updatePageName(
      newPageInfo.pageName,
      newPageInfo.pageType as PageType,
      newPageInfo.errorID,
      newPageInfo.errorName,
      newPageInfo.loginStatus as LoginStatus,
      newPageInfo.previousPageName ?? this.currentPageName
    );
    this.currentPageName = newPageInfo.pageName;
    if (this.initialized()) {
      window._satellite?.track('pageload');
    } else if (!this.listenerAdded) {
      const trackingScriptReady = () => {
        window._satellite?.track('pageload');
        window.removeEventListener(
          TRACKING_SCRIPT_READY_EVENT_NAME,
          trackingScriptReady
        );
      };
      window.addEventListener(
        TRACKING_SCRIPT_READY_EVENT_NAME,
        trackingScriptReady
      );
      this.listenerAdded = true;
    }
  };
  submitForm = (projectName: string): void => {};

  isInitialized(): boolean {
    if (!this.initialized())
      console.log('PaybackAnalyticsStrategy not initialized');
    return this.initialized();
  }

  initialized(): boolean {
    return !!(window as any).window.__satelliteLoaded;
  }

  scanForNewFormFields(formId: string): void {}
}
