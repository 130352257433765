import React, { FunctionComponent } from 'react';
import Element from './formularElement';
import LazyComponentLoader from '../componentLoading/lazyComponentLoader';
import ElementParser from './elementParser';

interface ComponentBuilderProps {
  element: Element;
  keys?: Array<number>;
}

const ComponentBuilder: FunctionComponent<ComponentBuilderProps> = ({
  element: { refComponent, elements, ...additionalProps },
  keys = [],
}) => {
  if (elements && elements.length > 0) {
    return (
      <LazyComponentLoader
        refComponent={refComponent}
        {...additionalProps}
        elements={elements}
      >
        <ElementParser keys={keys} elements={elements} />
      </LazyComponentLoader>
    );
  } else {
    return (
      <LazyComponentLoader refComponent={refComponent} {...additionalProps} />
    );
  }
};

export default React.memo(ComponentBuilder);
