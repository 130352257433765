export const typography = {
  fontFamily: 'Open Sans',
  Payback_script: {
    fontFamily: 'Payback_Script',
    color: '#C1002B',
  },
  body1: {
    fontFamily: 'Open Sans',
    color: '#4B4B4D',
  },

  body2: {
    fontFamily: 'Open Sans Bold',
    color: '#4B4B4D',
  },

  h3: {
    fontFamily: 'Payback_Rg',
    color: '#4B4B4D',
  },
  h4: {
    fontFamily: 'Payback_Rg, Payback',
    color: '#4B4B4D',
  },
  h5: {
    fontFamily: 'Payback',
    color: '#4B4B4D',
  },
};
