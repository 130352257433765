import React from 'react';

interface ConfigContextInterface {
  form?: Object;
}

const ConfigContext = React.createContext<ConfigContextInterface | null>({
  form: {},
});

export default ConfigContext;
