import React, { Component } from 'react';

interface LazyLoadingErrorBoundaryProps {
  debug?: boolean;
}

interface LazyLoadingErrorBoundaryState {
  hasError: boolean;
  errorMessage: Error | undefined;
}

class LazyLoadingErrorBoundary extends Component<
  LazyLoadingErrorBoundaryProps,
  LazyLoadingErrorBoundaryState
> {
  constructor(props: Readonly<LazyLoadingErrorBoundaryProps>) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: undefined,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error };
  }

  render() {
    if (this.state.hasError) {
      if (this.props.debug)
        return (
          <>
            <h1>Could not load Component</h1>
            <div
              style={{
                borderRadius: '5px',
                backgroundColor: '#f55',
                padding: '2em',
              }}
            >
              <div data-testid="message-container">
                Message: {this.state.errorMessage?.message}
              </div>
              <div data-testid="stacktrace-container">
                Stacktrace: {this.state.errorMessage?.stack}
              </div>
            </div>
          </>
        );
      return <h1>Something went wrong</h1>;
    }
    return this.props.children;
  }
}

export default LazyLoadingErrorBoundary;
