import { Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import { FunctionComponent, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import PbStepperImage from '../components/pbStepperImage/pbStepImage';
import { constants } from '../constants';
import LazyLoadingErrorBoundary from '../core/componentLoading/lazyLoadingErrorBoundary';
import LoadingComponent from '../core/componentLoading/loading';
import ComponentBuilder from '../core/formBuilder/componentBuilder';
import Element from '../core/formBuilder/formularElement';
import MetaDataContext from '../core/formBuilder/metaDataContext';
import PageStepperContextProvider from '../core/formBuilder/pageStepperContext';
import { DataSchemaContext } from '../core/validation/dataSchemaContext';
import desktopImageAmex from '../imgs/desktop/Amex_Antragsstrecke_Desktop.jpg';
import desktopImageVisa from '../imgs/desktop/Visa_Antragsstrecke_Desktop_1500x2000px.jpg';
import mobileImageAmex from '../imgs/mobile/Amex_Antragsstrecke_Visual_Handy.jpg';
import mobileImageVisa from '../imgs/mobile/Visa_Antragsstrecke_Visual_Handy_1200x201px.jpg';
import desktopImageAmexVers from '../imgs/desktop/PCH-15113_Antragsstrecke_Visual_Desktop_Tablet_mitKarte_1500x2000.jpg';
import mobileImageAmexVers from '../imgs/mobile/PCH-15113_Antragsstrecke_Visual_Mobile_mitKarte_1200x201.jpg';
import { enableLegacyTwoColumnLayout } from '../core/services/legacyTwoColumnLayoutService';

const PREFIX = 'FormPage';

const classes = {
  desktopScrollcontainer: `${PREFIX}-desktopScrollcontainer`,
};

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.desktopScrollcontainer}`]: {},
}));

interface FormPageProps {
  config: {
    form: {};
    intl: null;
    meta: {};
    dataSchema: {
      errorSchema: {};
      validationSchema: {};
    };
  };
}

export const FormPage: FunctionComponent<FormPageProps> = ({ config }) => {
  const theme = useTheme();
  const { project } = useParams();
  const MEDIAQUERY_smUP = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <PageStepperContextProvider>
      <DataSchemaContext.Provider
        value={{
          validationSchema: config.dataSchema.validationSchema ?? {},
          errorSchema: config.dataSchema.errorSchema ?? {},
        }}
      >
        <MetaDataContext.Provider value={{ meta: config.meta }}>
          <Grid
            style={{
              overflowX: 'hidden',
              width: '100%',
            }}
            container
            alignItems={constants.LAYOUT === 'SINGLE' ? 'center' : ''}
            justifyContent={constants.LAYOUT === 'SINGLE' ? 'center' : ''}
          >
            {constants.LAYOUT === 'DOUBLE' &&
              enableLegacyTwoColumnLayout(config) && (
                <PbStepperImage
                  desktopImage={
                    project === 'amex'
                      ? desktopImageAmex
                      : project === 'amexVers'
                      ? desktopImageAmexVers
                      : desktopImageVisa
                  }
                  mobileImage={
                    project === 'amex'
                      ? mobileImageAmex
                      : project === 'amexVers'
                      ? mobileImageAmexVers
                      : mobileImageVisa
                  }
                  objectPosition={
                    project === 'amex' ? 'left center' : 'top center'
                  }
                />
              )}
            <Grid
              style={{ overflowX: 'hidden', width: '100%', padding: '0' }}
              item
              sm
              lg
              className={MEDIAQUERY_smUP ? classes.desktopScrollcontainer : ''}
            >
              <LazyLoadingErrorBoundary
                debug={Boolean((window as any)._env_.DEBUG)}
              >
                <Suspense fallback={<LoadingComponent />}>
                  <ComponentBuilder element={config.form as Element} />
                </Suspense>
              </LazyLoadingErrorBoundary>
            </Grid>
          </Grid>
        </MetaDataContext.Provider>
      </DataSchemaContext.Provider>
    </PageStepperContextProvider>
  );
};
