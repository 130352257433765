import React from 'react';

interface IntlContextInterface {
  lang: string;
  dateFormat: string;
  thousandSeparator: string;
  decimalSeparator: string;
  currency: string;
  currencyDigits: string;
}

const IntlContext = React.createContext<IntlContextInterface | null>({
  lang: 'de',
  dateFormat: 'dd.MM.yyyy',
  thousandSeparator: '.',
  decimalSeparator: ',',
  currency: '€',
  currencyDigits: 'EUR',
});

export default IntlContext;
