import React from 'react';
import LazyComponentLoader from '../componentLoading/lazyComponentLoader';

interface GlobalComponentWrapperInterface {
  globalComponentConfig?: ComponentElement[];
}

export interface ComponentElement {
  refComponent: string;
  configurationProps?: { [key: string]: any };
}

export class GlobalComponentLoader extends React.Component<GlobalComponentWrapperInterface> {
  shouldComponentUpdate() {
    return !this.props.globalComponentConfig;
  }
  render() {
    const { globalComponentConfig } = this.props;

    return globalComponentConfig ? (
      globalComponentConfig?.map((component) => (
        <LazyComponentLoader
          key={component.refComponent}
          excludeGrid={true}
          {...component}
        />
      ))
    ) : (
      <></>
    );
  }
}
