import { IAnalyticsStrategy, PageInfo } from './IAnalyticsStrategy';

export class MatomoAnalyticsStrategy implements IAnalyticsStrategy {
  constructor() {
    setTimeout(() => {
      (window as any)._paq.push(['HeatmapSessionRecording::enable']);
    }, 3000);
  }
  pageChange = (newPageName: PageInfo): void => {
    //  mvp solution. It can happen that the heatmap snapshot is taken before rendering,
    //  so that no components are visible on the snapshot.
    // _paq is initialised in index.html ASB-820
    // https://matomo.org/faq/heatmap-session-recording/faq_25413/
    (window as any)?._paq?.push(['HeatmapSessionRecording::disable']);
    setTimeout(() => {
      (window as any)?._paq?.push(['HeatmapSessionRecording::enable']);
    }, 3000);
  };

  submitForm = (projectName: string): void => {
    this.isInitialized() &&
      (window as any)._paq.push([
        'trackEvent',
        'form submit',
        'submit',
        projectName,
      ]);
  };

  isInitialized(): boolean {
    if (!this.initialized())
      console.log('MatomoAnalyticsStrategy not initialized');
    return this.initialized();
  }

  initialized(): boolean {
    return !!(window as any).Matomo?.initialized;
  }

  scanForNewFormFields(formId: string): void {
    if (this.isInitialized()) {
      document.getElementById('justUpdatedElement');
      (window as any)._paq.push(['FormAnalytics::scanForForms', formId]);
    }
  }
}
