const basePath =
  (window as any)?._env_?.REACT_APP_PAYBACK_SERVICE_URL ??
  'http://localhost:3003/';
export const PAYBACK_SERVICE_URL = {
  base: basePath,
  V1: {
    getIBANpath: (project: string) =>
      basePath.concat(`v1/${project}/checkIban`),

    saveAndRetrieveDeleteProcess: (
      saveAndRetrieveCode: string,
      project: string
    ) =>
      basePath.concat('v1/saveAndRetrieve/').concat(saveAndRetrieveCode) +
      '?' +
      new URLSearchParams({ project }),
    SAVE_AND_RETRIEVE_UPDATE_PROCESS: basePath.concat('v1/saveAndRetrieve/'),
    PCE: basePath.concat('v1/pce'),
    SESSION: basePath.concat('v1/session'),
  },
};

export const pbConstants = {
  AMEX_BASE_URL_FOR_MISSING_URL_PARAMS:
    (window as any)?._env_?.REACT_APP_AMEX_BASE_URL_FOR_MISSING_URL_PARAMS ??
    'https://www.payback.de/info/american-express',
  VISA_BASE_URL_FOR_MISSING_URL_PARAMS:
    (window as any)?._env_?.REACT_APP_VISA_BASE_URL_FOR_MISSING_URL_PARAMS ??
    'https://www.payback.de/info/visa',
  PAYBACK_DEFAULT_FOR_MISSING_URL_PARAMS:
    (window as any)?._env_?.REACT_APP_PAYBACK_DEFAULT_FOR_MISSING_URL_PARAMS ??
    'https://www.payback.de',
};
