import { IAnalyticsStrategy, PageInfo } from './IAnalyticsStrategy';

export class NoAnalyticsStrategy implements IAnalyticsStrategy {
  constructor() {
    console.log('Initializing NoAnalyticsStrategy');
  }

  pageChange(pageInfo: PageInfo): void {}

  submitForm(projectName: string): void {}

  initialized(): boolean {
    return true;
  }

  scanForNewFormFields(formId: string): void {}
}
