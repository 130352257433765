const BACKEND_URL = (window as any)?._env_?.REACT_APP_BACKEND_URL;

export const API = {
  V3: {
    PROJECT: {
      ACTIVE_CONFIG: (projectname: string) =>
        `${BACKEND_URL}/v3/formbuilder/project/${projectname}/active`,
      CONFIG_BY_VERSION: (projectname: string, version: string) =>
        `${BACKEND_URL}/v3/formbuilder/project/${projectname}/version/${version}`,
    },
    FORM_DATA: {
      FORM_DATA: (configVersion: string, project: string) =>
        `${BACKEND_URL}/v3/formbuilder/formData?configVersion=${configVersion}&project=${project}`,
    },
    STATIC_RESOURCES: {
      STATIC_RESOURCE: (identifier: string) =>
        `${BACKEND_URL}/v3/formbuilder/staticResources/${identifier}`,
    },
  },
};
