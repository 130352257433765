import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  useState,
} from 'react';

interface GenericRestCallContextProps {
  submittedCalls: string[];
  setSubmittedCalls: Dispatch<string[]>;
}

export const GenericRestCallContext =
  createContext<GenericRestCallContextProps>({} as GenericRestCallContextProps);

const GenericRestCallContextProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [submittedCalls, setSubmittedCalls] = useState<string[]>([]);

  return (
    <GenericRestCallContext.Provider
      value={{
        submittedCalls,
        setSubmittedCalls,
      }}
    >
      {children}
    </GenericRestCallContext.Provider>
  );
};

export default GenericRestCallContextProvider;
