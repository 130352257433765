import { createTheme, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { Suspense, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Analytics } from './components/analytics/analytics';
import { constants } from './constants';
import LoadingComponent from './core/componentLoading/loading';
import GenericRestCallContextProvider from './core/contexte/genericRestCallContext';
import PostIdContextProvider from './core/contexte/postIdContext';
import URIParameterContextProvider from './core/contexte/URIParameterContext';
import GlobalErrorProvider from './core/globalErrorContext';
import ScrollToTop from './core/ScrollToTop/ScrollToTop';
import { ProjectPage } from './pages/projectPage';
import { typography } from './payback_typography';
import DEFAULT_THEME from './themes/default_theme';
import PrefillContextProvider from './core/contexte/prefillContext';

let showExitPrompt = true;
export const setShowExitPrompt = () => {
  showExitPrompt = false;
};

function App() {
  const addCustomThemeProperties = (theme: any) => {
    return constants.PATH_FOR_CUSTOM_STATIC_PAGES === 'payback'
      ? deepmerge(
          {
            typography: typography,
            spacing: 16,
          },
          theme
        )
      : theme;
  };
  const [theme, setTheme] = useState({});
  return (
    <BrowserRouter>
      <GlobalErrorProvider>
        <URIParameterContextProvider>
          <ScrollToTop />
          <Analytics />
          <PostIdContextProvider>
            <Suspense fallback={<LoadingComponent />}>
              <GenericRestCallContextProvider>
                <ThemeProvider
                  theme={
                    theme
                      ? createTheme(addCustomThemeProperties(theme))
                      : createTheme(DEFAULT_THEME)
                  }
                >
                  <PrefillContextProvider>
                    <Routes>
                      <Route
                        element={<ProjectPage setTheme={setTheme} />}
                        path="/:project/*"
                      />
                    </Routes>
                  </PrefillContextProvider>
                </ThemeProvider>
              </GenericRestCallContextProvider>
            </Suspense>
          </PostIdContextProvider>
        </URIParameterContextProvider>
      </GlobalErrorProvider>
    </BrowserRouter>
  );
}

export default App;
