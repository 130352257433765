import { ThemeOptions } from '@mui/material';

const DEFAULT_THEME: ThemeOptions = {
  spacing: 12,
  palette: {
    primary: {
      light: '#ceff80',
      main: '#93f100',
      dark: '#74bd00',
      contrastText: '#51504e',
    },

    secondary: {
      light: '#e2ff6e',
      main: '#cfff0f',
      dark: '#88cc00',
      contrastText: '#fff',
    },
    error: {
      light: '#ffa6a6',
      main: '#ff6161',
      dark: '#ff0000',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffdea6',
      main: '#ffc926',
      dark: '#de8900',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      light: '#5cffff',
      main: '#35bef0',
      dark: '#1a7feb',
      contrastText: '#fff',
    },
  },
};

export default DEFAULT_THEME;
