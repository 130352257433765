import React, { createContext, FunctionComponent, useState } from 'react';
import { createSearchParams, useLocation } from 'react-router-dom';
import { PB_MGM_REF_PARAMETER } from '../../asb_modules/payback/Utils/urlParameterCheck';

//TODO add generic way to insert paramters
const URI_PARAMETER = [
  'incid',
  'sales_code',
  'source_code',
  'spid',
  'logo',
  'pct',
  'dealid',
  'partnerid',
  //   'Alias'
  PB_MGM_REF_PARAMETER,
];

export const URIParameterContext = createContext<any>({
  URLSearchParams: '',
  URIParameter: '',
  setURIParameter: () => {},
  restoreURIParameterFromLocalStorage: () => {},
});

const URIParameterContextProvider: FunctionComponent<any> = ({ children }) => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const [URIParameter, setURIParameter] = useState(
    createSearchParams(urlSearchParams).toString()
  );

  const restoreURIParameterFromLocalStorage = (stateData: any) => {
    let newParameter: any = '';

    URI_PARAMETER.forEach((parameter) => {
      if (Object.keys(stateData).includes(parameter))
        newParameter += parameter + '=' + stateData[parameter] + '&';
    });

    setURIParameter(newParameter);
  };

  return (
    <URIParameterContext.Provider
      value={{
        URIParameter: URIParameter,
        restoreURIParameterFromLocalStorage:
          restoreURIParameterFromLocalStorage,
      }}
    >
      {children}
    </URIParameterContext.Provider>
  );
};

export default URIParameterContextProvider;
